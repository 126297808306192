  @import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,400;1,300;1,400&display=swap');


*{
  box-sizing: border-box;
  font-family: 'Lato', sans-serif;
  margin: 0;
  padding: 0;
}

.displayNone{
visibility: hidden;
}

.heroSection{

  position: absolute;
  top: 0;
  left: 0;
  height: 500px;
  width: 100%;
  background-color: transparent;
  color: aliceblue;
  overflow: hidden;
  white-space: nowrap;
  pointer-events: none;

}
.wrapper{
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  height: 100%;


}
.gallerySection{
  position: absolute;
  top: 500px;
  min-height: 50vh;
  left: 0;
  width: 100%;
  overflow: hidden;
  background-color: transparent;
  color: aliceblue;

}
.secTwoBg{
  position: relative;
}

.container{
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-self: center;
  text-align: center;
  padding: 20px;
}

.sectionTitle {
  display: flex;
  align-items: center;
     background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(15px);
  

}

.sectionTitle:before,
.sectionTitle:after {
  content: "";
  flex-grow: 1;
  height: 1px;
  background-color: white;
  border-radius: 0.5px;
}

.sectionTitle h1 {
  margin: 0 15px;
}

.cards{
  padding: 20px;
    display: flex;
  flex-wrap: wrap;
  row-gap: 20px;

}
 .card{
    flex: 1;

  display: flex;
  row-gap: 20px;
  flex-basis: 25%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 250px;
  color: rgb(255, 255, 255);
}
.imgDate{
  background-color: aliceblue;
  color: rgb(17, 75, 17);
  width: 150px;
  height: 2rem;
  border-radius: 10px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

}


.saved-image{
  width: 150px;
  height: 150px;


}

.imgContainer{
  position: relative;

}


.imgContainer .thumbDel{
  position: absolute;
  opacity: 0;
  right: 0;
  top: 0;
  transform: translate(-25%,25%);
  pointer-events: auto;
  z-index: 200;
}
.imgContainer:hover .thumbDel{
  opacity: 1;    
  background-color: rgba(58, 57, 57, 0.8);
    color: aliceblue;
    backdrop-filter: blur(10px);
    height: 20px;
    width: 20px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
    

img{
opacity: 0;

}

.noSavedImg{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  margin-top: 30px;
    background-color: rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(2px);


  color: aliceblue;
}
.infOpenBtn{
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  top: 0;
  right: 0;
  width: 60px;
  height: 60px;
  border-radius: 30px;
  font-size: 3rem;
  transform: translate(-50%,50%);
  border: 4px solid aliceblue;
  color: aliceblue;
  opacity: 0.25;
  transition: opacity 0.3s ease-in-out; 
    animation: blink 3s infinite alternate;

}
/* Keyframes for the blinking effect */
@keyframes blink {
  from {
    opacity: 0.25;
  }
  to {
    opacity: 1;
  }
}
.infOpenBtn:hover{
  opacity: 1;
  cursor: pointer;
  animation: blink 2s infinite alternate;

}

.info{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  background-color: black;
  color: aliceblue;
  overflow: hidden;
  padding: 10px 20px;
  
}
.info p{
  font-size: 1.5rem;
    font-style: italic;

}
.infoWrapper{
  max-width: 1200px;
  margin: 0 auto;
  height: 100%;
}
.infoContainer{
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}
.closeInf{
  position: absolute;
  right: 0;
  top: 0;
  transform: translate(-25%,25%);
  pointer-events:auto;
  cursor: pointer;
  font-size: 2rem;
  opacity: 0.5;
  transition: opacity 0.3s ease-in-out; 
}
.closeInf:hover{
  opacity: 1;
}
/* .particleSampler{
  position: absolute;
  bottom: 0;
} */
.particleSampler{
  width: 100%;
  height: 500px;
margin-top: 20px;
}
.samplereParticlesCanvas{
}



.info p {
  opacity: 0;
  transition: opacity 1s ease;
  text-align: center;
    width: 100%;

}
.info.show {
  display: block;
}

.info.show p {
  opacity: 1;
}
/* Sequential delays for each line */
.info.show .line-1 {
  transition-delay: 0.2s;
  font-size: 2rem;
}

.info.show .line-2 {
  transition-delay: 0.6s;
}

.info.show .line-3 {
  transition-delay: 0.8s;
}

.info.show .line-4 {
  transition-delay: 1.1s;
}

.info.show .line-5 {
  transition-delay: 1.4s;
}

svg{
  display: none;
}

