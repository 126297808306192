
.invisible{
    display: none;
}
.selectedImgOverlay{
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    /* z-index: 1000; */
    color: azure;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(15px);
    overflow: hidden;

}

.innerContainer{
    position: relative;

    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    column-gap: 20px;
    padding: 20px;
}
.cell-2{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;
    flex-basis: 50%;
}
.cell-1{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    flex-basis: 50%;
    
}
.selectedImgIcons{
    position: relative;
    display: flex;
    width: 100%;
    color: aliceblue;
    z-index: 100;
}

.selectedImgOverlay img{
    opacity: 1;
    max-width: 100%;
    max-height: 100%;
    border-radius: 10px;

}
.selectedImgIcons .icons{
    font-size: 2rem;
    position: absolute;
    display: flex;
    column-gap: 10px;
    bottom: 0;
    right: 0;
    padding: 0 10px 5px 0;
    color: rgba(255, 255, 255, 0.5);

}
.selectedImgIcons .icons span:hover{
    color: rgba(255, 255, 255, 0.9);

}
.closeBtn{
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    color: aliceblue;
    font-size: 2.5rem;
    transform: translate(-50%,50%);
    cursor: pointer;
    z-index: 200;
}

.savedBtn{
    color:aliceblue
}
 @media screen and (max-width: 700px) {
    .innerContainer{
        flex-direction: column;
        overflow-y: scroll;
    }
  }