.datePicker{
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  align-items: center;
  justify-content: center;
  pointer-events: all;
}

.monthsBox{
    border-left: 2px solid white;
    border-right: 2px solid white;
    padding: 0 20px;
}



.visibleDateBox{
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  align-items: center;
  justify-content: center;
  pointer-events: all;

}

select{
    appearance: none;
    text-align: center;
    text-align-last: center;
    border: none;
    padding: 8px;
    width: 100px;
    background-color: rgba(255, 255, 255, 0.1);
    color: aliceblue;
    backdrop-filter: blur(10px);
    cursor: pointer;
    position: relative;



}

select option{
    position: relative;
    text-align: center;
    padding-left:0px;
    padding: 8px;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.1);
    color: rgb(2, 18, 31);
    backdrop-filter: blur(10px);
    cursor: pointer;
}

.btn-div{
        position: relative;

    width: 100px;
    padding-left: 0;
    margin: 0;
    background-color: rgba(255, 255, 255, 0.1);
    color: aliceblue;
    backdrop-filter: blur(10px);
    cursor: pointer;
    padding: 5px 15px;
    border: 2px solid rgba(255, 255, 255, 0.5);
    border-radius: 5px;
}
.btn-div p{
    padding: 0;
    margin: 0;
    text-decoration: none;
    color: aliceblue
}

